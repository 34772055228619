import album1 from '../images/merch/Album_1.jpg';
import album2 from '../images/merch/Album_2.jpg';
import album3 from '../images/merch/Album_3.jpg';

import logoShirt from '../images/merch/Logo_Shirt.jpg';
import serpentShirt from '../images/merch/serpent.jpg';
import begrabenShirt from '../images/merch/Begraben_Shirt_Front.jpg';
import angristShirt from '../images/merch/Angrist_Shirt.jpg';
import weaponShirt from '../images/merch/Weapon.jpg';
import angristLongSleeve from '../images/merch/Angrist_Longsleeve.jpg';
import logoHoodie from '../images/merch/LogoHoodie.jpg';
import albumZipper from '../images/merch/zipper.jpg';
import albumZipperBack from '../images/merch/zipperback.jpg';
import albumHoodie from '../images/merch/Album_Hoodie_Back.jpg';
import albumHoodieFront from '../images/merch/LogoHoodie.jpg';
import tanktopShaman from '../images/merch/Shaman_Tanktop.jpg';
import hatSnap from '../images/merch/snapcap.jpg';
import hatDad from '../images/merch/dadcap.jpg';
import beanie from '../images/merch/Hat.jpg';
import patch from '../images/merch/Patches.jpg';
import freeStuff from '../images/merch/Free_Stuff.jpg';
import met from '../images/merch/Met.jpg';
import metHoney from '../images/merch/MetHoney.jpg';
import metCherry from '../images/merch/MetCherry.jpg';

import vinyl1 from "../images/merch/Vinyl1.jpg";
import vinyl2 from "../images/merch/Vinyl2.jpg";
import vinyl3 from "../images/merch/Vinyl3.jpg";
import vinyl4 from "../images/merch/Vinyl4.jpg";

export const MERCH_ARTICLES = [
    {
        title: 'Album Frostshock Vinyl',
        price: '30 €',
        images: [
            vinyl1,
            vinyl2,
            vinyl3,
            vinyl4
        ],
        availabilty: ''
    },
    {
        title: 'Album  Frostshock CD (Digipack)',
        price: '15 €',
        images: [
            album1,
            album2,
            album3
        ],
        availabilty: ''
    },
    {
        title: 'Logo-Shirt',
        price: '10 €',
        images: [
            logoShirt
        ],
        availabilty: ''
    },
    {
        title: 'A Weapon Called Time Shirt',
        price: '15 €',
        images: [
            weaponShirt
        ],
        availabilty: ''
    },
    {
        title: 'The Serpent Inside Shirt',
        price: '15 €',
        images: [
            serpentShirt
        ],
        availabilty: 'Only S and XL'
    },
    {
        title: 'Begraben im Eis Shirt',
        price: '15 €',
        images: [
            begrabenShirt
        ],
        availabilty: ''
    },
    {
        title: 'Angrist Shirt',
        price: '15 €',
        images: [
            angristShirt
        ],
        availabilty: ''
    },
    {
        title: 'Angrist-Longsleeve',
        price: '20 €',
        images: [
            angristLongSleeve
        ],
        availabilty: 'Sold out'
    },
    // {
    //     title: 'Logo-Hoodie',
    //     price: '25 €',
    //     images: [
    //         logoHoodie
    //     ],
    //     availabilty: 'Sold out'
    // },
    {
        title: 'Frostshock-Zipper',
        price: '40 €',
        images: [
            albumZipper,
            albumZipperBack
        ],
        availabilty: ''
    },
    {
        title: 'Frostshock-Hoodie',
        price: '35 €',
        images: [
            albumHoodie,
            albumHoodieFront
        ],
        availabilty: ''
    },
    {
        title: 'Tanktop - Shaman',
        price: '10 €',
        images: [
            tanktopShaman
        ],
        availabilty: ''
    },
    {
        title: 'Hat (Snap)',
        price: '15 €',
        images: [
            hatSnap
        ],
        availabilty: 'Sold out'
    },

    {
        title: 'Hat (Dad)',
        price: '15 €',
        images: [
            hatDad
        ],
        availabilty: ''
    },
    {
        title: 'Beanie',
        price: '15 €',
        images: [
            beanie
        ],
        availabilty: ''
    },
    {
        title: 'Patch',
        price: '5 €',
        images: [
            patch
        ],
        availabilty: ''
    },
    {
        title: 'Free stuff with every purchase',
        price: '0 €',
        images: [
            freeStuff
        ],
        availabilty: ''
    },
    {
        title: 'Met - Honey',
        price: '10 €',
        images: [
            metHoney
        ],
        availabilty: 'Sold at gigs only'
    },
    {
        title: 'Met - Cherry',
        price: '10 €',
        images: [
            metCherry
        ],
        availabilty: 'Sold at gigs only'
    },
]