import MediaContainer from "../components/Content/MediaContainer";
import VinylSlider from "../components/Content/Sliders/VinylSlider";

export default function Home(){
    return(
        <>
            {/* <div className="content">
                <div className="media-content">
                    <div className="content-text">
                        <h3 className="content-headline">Our album "Frostshock" is out now! We also have a new single and new merch!</h3>
                        <p>See links below to get a digital copy, or just write us an e-mail to get physical copy</p>
                    </div>
                    <div className="album-container">
                        <a href="https://linktr.ee/frostshock" className="album-ref">
                            <img src="images/LogoUndBand.jpg" className="album-image"/>
                        </a>
                        <div className="album-text-content">
                            <div className="album-link-container">
                                <a href="https://frostshock.bandcamp.com/album/frostshock" className="fa fa-bandcamp album-ref"> Get it on Bandcamp</a>
                                <a href="https://distrokid.com/hyperfollow/frostshock/frostshock" className="fa fa-spotify album-ref"> Get it on Spotify and other platforms</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="content">
                <MediaContainer headLine='Check out our new single: Whatever Lies Beyond The Black Sky' link='https://www.youtube.com/embed/A01gLvw81aw'/>            
            </div> */}
            <div className="content">
                <VinylSlider/>
            </div>
        </>
    );
}