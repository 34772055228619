import vinyl1 from "../../../images/merch/Vinyl1.jpg";
import vinyl2 from "../../../images/merch/Vinyl2.jpg";
import vinyl3 from "../../../images/merch/Vinyl3.jpg";
import vinyl4 from "../../../images/merch/Vinyl4.jpg";

import { Slide } from 'react-slideshow-image';
import "react-slideshow-image/dist/styles.css";
import '../../../styles/VinylSlider.scss';
  
const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover'
}

export default function VinylSlider() {

    const slideImages = [vinyl1,vinyl2,vinyl3,vinyl4]

    return (
        <div className="vinyl-slider">
            <h2 className="vinyl-slider-headline">Now on Vinyl! Limited Edition (500) of our Debut-Album "Frostshock".</h2>
            <div className="vinyl-slider-slide">
                <Slide>
                    {slideImages.map((slideImage, index)=> (
                        <div key={index}>
                            <div className="slide-image" style={{ ...divStyle, 'backgroundImage': `url(${slideImage})` }} />
                        </div>
                    ))} 
                </Slide>
            </div>
        </div>
    );
}