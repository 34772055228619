import { useState } from "react";
import ImageSelector from "./ImageSelector";
import '../../styles/ImageSelect.scss';
// import { CartContext } from "../../store/CartProvider";
// import { useContext } from "react";
import '../../styles/Merch.scss';

export default function MerchArticle({title, price, availability, images}){
    const [selectedImage, setSelectedImage] = useState(images[0]);
    //const cartCtx = useContext(CartContext);

    function handleClick(clickedImage) {
        setSelectedImage(clickedImage);
    }

    return (
        <div className="merch-container">
            <div className="merch-content">
                <div className="merch-title">{title}</div>
                <div className="merch-price">{price}</div>
            </div>
            <div className="merch-image">
                {images.length > 1 &&                 
                    <div className="image-select">
                        {images.map( (image, index) => <ImageSelector 
                                        key={image + index}
                                        onSelection={handleClick}
                                        isSelected={image === selectedImage}
                                        image={image}
                                        index={index} />
                        )}
                    </div>}
                <img src={selectedImage} alt="merch-article" loading="lazy" />
                <div className="merch-availability">{availability}</div>
                {/* <button onClick={() => cartCtx.addItem(title)}>+</button> */}
            </div>
        </div>
    );
}